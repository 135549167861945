import gql from "graphql-tag";

export const QUERY_ACCOUNT_LOGIN = gql`
  query AccountLogin($input: LoginInput) {
    AccountLogin(input: $input) {
      data {
        access_token
        expires_in
        token_type
        refresh_token
        scope
      }
      code
      message
    }
  }
`;

export const QUERY_REFRESH_TOKEN = gql`
  query RefreshToken($input: RefreshInput) {
    RefreshToken(input: $input) {
      data {
        access_token
        expires_in
        token_type
        refresh_token
        scope
      }
      code
      message
    }
  }
`;

export const QUERY_ACCOUNT_DETAIL = gql`
  query {
    AccountDetail {
      data {
        name
        nick_name
        email
        telephone
        hotel_id
        hotel_name
        roles
      }
      code
      message
    }
  }
`;

export const QUERY_ROOM_STATUS_TOTAL = gql`
  query RoomStatusTotal($input: TotalRoomStatusInput) {
    RoomStatusTotal(input: $input) {
      data {
        total_room
        room_status {
          status
          label
          total
        }
      }
      message
      code
    }
  }
`;

export const QUERY_ROOMS = gql`
  query Rooms {
    Rooms {
      data {
        number
        name
        type
        status
        status_id
        qr_code
        room_id
        mqtt {
          room
          hotel
        }
        is_pantoo
        is_extrabed
        iot_attributes {
          device_list {
            aria {
              ae_resource_id
              ae_resource_name
            }
            bpad {
              ae_resource_id
              ae_resource_name
            }
            roca {
              ae_resource_id
              ae_resource_name
            }
            pantoo {
              ae_resource_id
              ae_resource_name
            }
          }
          provision_data {
            ble {
              key
              tag
            }
            mqtt {
              port 
              ca_cert
              password
              username
              host_name
            }
            one_m2m {
              ae_resource_id
              ae_resource_name
              cse_resource_id
              cse_resource_name
            }
          }
        }
      }
      message
      code
    }
  }
`;

export const QUERY_ROOM_STATUSES = gql`
  query RoomStatuses($input: RoomInput) {
    RoomStatuses(input: $input) {
      data {
        id
        name
        status
        label
        total
      }
      message
      code
    }
  }
`;

export const QUERY_ROOM_NOTES = gql`
  query RoomNotes {
    RoomNotes {
      data {
        id
        code
        status
        title
      }
      message
      code
    }
  }
`;

export const QUERY_NIGHT_AUDIT_LIST = gql`
  query ReservationNightAudit($input: NightAuditInput) {
    ReservationNightAudit(input: $input) {
      data {
        verified {
          need_to_check
          need_to_verify
          verified
        }
        list {
          reservation_id
          order_id
          source_name
          is_net_revenue_verified
          is_net_revenue_verified_code
          is_net_revenue_verified_label
          room_name
          room_type_name
          room_type_label
          guest_name
          gross_revenue_label
          net_revenue_label
          total_net_revenue_label
          ota_id
          ota_rate_plan_id
          ota_promotion_id
        }
      }
      message
      code
    }
  }
`;

export const QUERY_NIGHT_AUDIT_PREVIEW = gql`
  query ReservationNightAuditPreview($input: NightAuditPreviewInput) {
    ReservationNightAuditPreview(input: $input) {
      data {
        reservation_id
        order_id
        source_name
        is_net_revenue_verified
        is_net_revenue_verified_code
        is_net_revenue_verified_label
        rooms {
          room_id
        }
        guest_name
        guest_price
        gross_revenue
        net_revenue
        total_net_revenue
        note
        ota_id
        ota_commission_price
        ota_commission_percentage
        ota_rate_plan_id
        ota_rate_plan_price
        ota_rate_plan_percentage
        ota_promotion_id
        ota_promotion_price
        ota_promotion_percentage
        created_datetime
        is_verified
        verified_datetime
        verified_by
        is_checked
        checked_datetime
        checked_by
      }
      message
      code
    }
  }
`;

export const QUERY_OTA_RATE_PLAN = gql`
  query OtaRatePlans($input: RatePlanInput) {
    OtaRatePlans(input: $input) {
      data {
        id
        name
      }
      message
      code
    }
  }
`;

export const QUERY_OTA_PROMOTION = gql`
  query OtaPromotions($input: PromotionInput) {
    OtaPromotions(input: $input) {
      data {
        id
        name
      }
      message
      code
    }
  }
`;

export const QUERY_NIGHT_AUDIT_MONTHLY = gql`
  query ReservationNightAuditMonthly($input: NightAuditMonthlyInput) {
    ReservationNightAuditMonthly(input: $input) {
      data {
        date
        is_blackout
        verified {
          need_to_check
          need_to_verify
          verified
          status
        }
      }
      message
      code
    }
  }
`;

export const QUERY_RESERVATION_LIST = gql`
  query Reservations($input: ReservationsInput) {
    Reservations(input: $input) {
      data {
        reservation_id
        order_id
        payment_status_id
        payment_status_label
        guest_name
        guest_email
        guest_phone
        check_in_date
        check_out_date
        reservation_date
        total_room
        reservation_source_label
        reservation_source_id
      }
      message
      code
    }
  }
`;

export const QUERY_RESERVATION_DETAIL = gql`
  query ReservationDetail($input: ReservationDetailInput) {
    ReservationDetail(input: $input) {
      data {
        order_id
        reservation_type
        reservation_id
        reservation_date
        reservation_source
        reservation_source_id
        check_in_date
        check_out_date
        duration
        duration_label
        guest_name
        guest_email
        guest_phone
        ota_id
        total_room
        payment_status_id
        payment_status_label
        payment_type
        payment_type_id
        notes
        mark_as_deleted
        mark_as_deleted_label
        is_removed
        is_removed_label
        detail {
          retail_rate_price
          retail_rate_price_label
          rate_plan_price
          promotion_price
          promotion_price_label
          promotion_code
          gift_certificate
          gift_certificate_label
          addon_price
          addon_price_label
          bank_fee
          bank_fee_label
          midtrans_fee
          midtrans_fee_label
          guest_price
          guest_price_label
          net_price
          net_price_label
          is_net_revenue_verified
          is_net_revenue_verified_code
          is_net_revenue_verified_label
        }
        stays {
          stay_id
          user_id
          name
          check_in_date
          check_out_date
          stay_status
          stay_status_label
          room_id
          room_name
          room_type_label
        }
        room_revenue_breakdown {
          room_id
          room_name
          room_type
          revenue_breakdown {
            date
            retail_rate
            rate_plan_price
            rate_plan_price_label
            promotion_price
            promotion_price_label
            guest_price
            guest_price_label
            net_price
            ota_commission
            ota_commission_label
            gift_certificate
            gift_certificate_label
          }
        }
      }
      message
      code
    }
  }
`;

export const QUERY_RESERVATION_DELETE_REASONS = gql`
  query ReservationDeleteReasons {
    ReservationDeleteReasons {
      data {
        id
        type
        reason
        status
      }
      message
      code
    }
  }
`;

export const QUERY_RESERVATION_SEND_RECEIPT = gql`
  query ReservationSendInvoice($input: ReservationSendInvoiceInput) {
    ReservationSendInvoice(input: $input) {
      data {
        reservation_id
      }
      message
      code
    }
  }
`;

export const QUERY_LIST_PAYMENT_STATUS = gql`
  query ReservationPaymentStatuses {
    ReservationPaymentStatuses {
      data {
        id
        name
      }
      message
      code
    }
  }
`;
export const QUERY_RESERVATION_SOURCES = gql`
  query ReservationSources($input: ReservationSourceInput) {
    ReservationSources(input: $input) {
      data {
        id
        name
        override_price
      }
      code
      message
    }
  }
`;

export const QUERY_RESERVATION_PAYMENT_TYPE = gql`
  query ReservationPaymentTypes($input: PaymentTypeInput) {
    ReservationPaymentTypes(input: $input) {
      data {
        id
        name
      }
      message
      code
    }
  }
`;

export const QUERY_RESERVATION_MAX_ROOM = gql`
  query RoomTotal($input: TotalRoomInput) {
    RoomTotal(input: $input) {
      data {
        total
      }
      code
      message
    }
  }
`;

export const QUERY_RESERVATION_AVAILABILITY = gql`
  query RoomAvailabilities($input: RoomAvailabilitiesInput) {
    RoomAvailabilities(input: $input) {
      data {
        room_id
        room_name
        room_type_label
        room_type_name
        room_price_type
        base_price
        gross_price
        peak_hour_price
        bundling_hour_price
        promotion_price
        net_price
      }
      message
      code
    }
  }
`;

/**
 * QUERY_STAY_LIST
 */
export const QUERY_STAY_LIST = gql`
  query Stays($input: StayListInput) {
    Stays(input: $input) {
      data {
        stay_id
        duration
        duration_label
        stay_status_id
        stay_status_label
        room_status_id
        room_status_label
        qr_code
        check_in_date
        check_out_date
        room_name
        room_type_id
        room_type_label
        guest_name
        guest_email
        guest_phone
        source_id
        source_label
        adult_capacity
        child_capacity
      }
      message
      code
    }
  }
`;

export const QUERY_STAY_DETAIL = gql`
  query StayDetail($input: StayDetailInput) {
    StayDetail(input: $input) {
      data {
        reservation_id
        reservation_date
        order_id
        payment_type_id
        payment_type_label
        payment_status_id
        payment_status_label
        note
        mark_as_deleted
        mark_as_deleted_label
        is_removed
        is_removed_label
        is_pod_assigned
        total_pods
        ota_id
        source_id
        source_label
        stay_id
        stay_uuid
        duration
        duration_label
        stay_status_id
        stay_status_label
        room_status_id
        room_status_label
        qr_code
        check_in_date
        check_out_date
        liveness_count_retry
        room_id
        room_name
        room_type_id
        room_type_label
        room_iot_attributes {
          device_list {
            aria {
              ae_resource_id
              ae_resource_name
            }
            bpad {
              ae_resource_id
              ae_resource_name
            }
            roca {
              ae_resource_id
              ae_resource_name
            }
            pantoo {
              ae_resource_id
              ae_resource_name
            }
          }
          provision_data {
            ble {
              key
              tag
            }
            mqtt {
              port 
              ca_cert
              password
              username
              host_name
            }
            one_m2m {
              ae_resource_id
              ae_resource_name
              cse_resource_id
              cse_resource_name
            }
          }
        }
        room_is_extrabed
        room_is_pantoo
        hotel_type
        adult_capacity
        child_capacity
        primary_guest {
          id
          name
          email
          phone
          nick_name
          is_email_verified
          is_telephone_verified
          is_idcard_verified
          is_idcard_verified_label
          idcard_url
          birthday
          gender
          identity_job_description
          identity_card_selfie_url
          identity_fullname
          identity_card_number
          identity_expired_status
          identity_expired_date
          identity_type_id
          identity_type_label
          identity_status
          country_id
          country_name
          flag_url
          qr_code
          city_id
          province_id
        }
        secondary_guest {
          id
          name
          email
          phone
          nick_name
          is_email_verified
          is_telephone_verified
          is_idcard_verified
          idcard_url
          birthday
          gender
          identity_job_description
          identity_card_selfie_url
          identity_fullname
          identity_card_number
          identity_expired_status
          identity_expired_date
          identity_type_id
          identity_type_label
          identity_status
          country_id
          country_name
          flag_url
          qr_code
          city_id
          province_id
        }
        shared_guests {
          id
          name
          email
          phone
          nick_name
          is_email_verified
          is_telephone_verified
          is_idcard_verified
          idcard_url
          birthday
          gender
          identity_job_description
          identity_card_selfie_url
          identity_fullname
          identity_card_number
          identity_expired_status
          identity_expired_date
          identity_type_id
          identity_type_label
          identity_status
          country_id
          country_name
          flag_url
          qr_code
          city_id
          province_id
        }
      }
      message
      code
    }
  }
`;

export const QUERY_LIST_STAY_STATUS = gql`
  query ListStaystatus {
    ListStayStatus {
      data {
        id
        name
      }
      message
      code
    }
  }
`;

export const QUERY_STAY_SEARCH_ASSIGN_ROOM = gql`
  query StaySearchAssignRoom($input: StaySearchAssignRoomInput) {
    StaySearchAssignRoom(input: $input) {
      data {
        guest_id
        guest_name
        guest_email
        guest_phone
      }
      message
      code
    }
  }
`;

export const QUERY_STAY_SEARCH_CHANGE_ROOM = gql`
  query StaySearchChangeRoom($input: StaySearchChangeRoomInput) {
    StaySearchChangeRoom(input: $input) {
      data {
        room_id
        room_name
        room_type_id
        room_type_label
        hotel_id
        stay_id
        room_price_type_id
      }
      message
      code
    }
  }
`;

export const QUERY_STAY_SEARCH_SWAP_ROOM = gql`
  query StaySearchSwapRoom($input: StaySearchSwapRoomInput) {
    StaySearchSwapRoom(input: $input) {
      data {
        room_id
        room_name
        room_type_id
        room_type_label
        hotel_id
        stay_id
      }
      message
      code
    }
  }
`;

export const QUERY_REPORT_OCCUPANCY_PER_HOTEL = gql`
  query ReportOccupancyPerHotel($input: OccupancyPerHotelInput) {
    ReportOccupancyPerHotel(input: $input) {
      code
      message
      data {
        date_start
        date_end
        hotel_ids
        details {
          date
          hotel_id
          total_rooms
          sold_rooms
          direct_paid_rsv_count
          ota_paid_rsv_count
          other_paid_rsv_count
          spare_rooms
          ooo_rooms
          stop_sell_limit_web
          stop_sell_limit_app
          blackout_days
          web_sellable_rooms
          app_sellable_rooms
          available_rooms
          unsold_rooms
          occupancy_rate
        }
        summaries {
          hotel_id
          total_rooms
          sold_rooms
          direct_paid_rsv_count
          ota_paid_rsv_count
          other_paid_rsv_count
          spare_rooms
          ooo_rooms
          stop_sell_limit_web
          stop_sell_limit_app
          blackout_days
          web_sellable_rooms
          app_sellable_rooms
          available_rooms
          unsold_rooms
          occupancy_rate
        }
      }
    }
  }
`;

export const QUERY_REPORT_STAY_STATUS_SUMMARY = gql`
  query ReportStayStatusSummary($input: StayStatusSummaryInput) {
    ReportStayStatusSummary(input: $input) {
      code
      message
      data {
        hotel_id
        status_id
        status_name
        count
      }
    }
  }
`;
export const QUERY_REPORT_STAY_EXPECTED_ARRIVAL_SUMMARY = gql`
  query ReportStayExpectedArrivalSummary(
    $input: StayExpectedArrivalSummaryInput
  ) {
    ReportStayExpectedArrivalSummary(input: $input) {
      code
      message
      data {
        hotel_id
        count
      }
    }
  }
`;
export const QUERY_REPORT_STAY_EXPECTED_DEPARTURE_SUMMARY = gql`
  query ReportStayExpectedDepartureSummary(
    $input: StayExpectedDepartureSummaryInput
  ) {
    ReportStayExpectedDepartureSummary(input: $input) {
      code
      message
      data {
        hotel_id
        count
      }
    }
  }
`;

export const QUERY_NIGHT_AUDIT_NEED_TO_CHECK = gql`
  query ReservationNightAuditNeedToCheck($input: NightAuditNeedToCheckInput) {
    ReservationNightAuditNeedToCheck(input: $input) {
      code
      message
      data {
        hotel_id
        check_in_date
        total
      }
    }
  }
`;

export const QUERY_ROOM_RATE_LIST = gql`
  query RoomAvailablePricePerRoomType($input: AvailablePricePerRoomTypeInput) {
    RoomAvailablePricePerRoomType(input: $input) {
      code
      message
      data {
        room_type
        room_type_name
        room_type_label
        price_type
        price
        available_rooms
        main_picture_url
      }
    }
  }
`;

export const QUERY_REJECT_IDENTITY_REASONS = gql`
  query AccountRejectIdentityReasons {
    AccountRejectIdentityReasons {
      message
      code
      data {
        id
        reason
        ordering
      }
    }
  }
`;

export const QUERY_STAY_MANUAL_CHECKIN_REASONS = gql`
  query StayManualCheckinReasons {
    StayManualCheckinReasons {
      message
      code
      data {
        id
        ordering
        reason
      }
    }
  }
`;
export const QUERY_STAY_BUTTON_LIST = gql`
  query StayButtonList($input: StayButtonListInput) {
    StayButtonList(input: $input) {
      message
      code
      data {
        is_check_in
        is_check_out
        is_change_pod
        is_swap_pod
        is_assign_pod
        is_split_pod
        is_get_qrcode
        is_resend_qrcode
      }
    }
  }
`;

export const QUERY_ROOM_QR_CODE_CLEANER = gql`
  query QRCodeRoomCleaner($input: QRCodeRoomCleanerTypeInput) {
    QRCodeRoomCleaner(input: $input) {
      data {
        qr_code
      }
      message
      code
    }
  }
`;

export const QUERY_MQTT_PUBLISH_TOPIC = gql`
  query MqttPublishTopic($input: MqttPublishTopicInput) {
    MqttPublishTopic(input: $input) {
      data {
        topic
        payload
      }
      message
      code
    }
  }
`;

export const QUERY_CLEANING_TASK = gql`
  query ListCleaningTask($input: CleaningTaskInput) {
    ListCleaningTask(input: $input) {
      data {
        id
        task
        hotel_type
        description
        room_type
      }
      code
      message
    }
  }
`;

export const QUERY_STAY_LIST_DOWNLOAD = gql`
  query StayListDownload($input: StayListDownloadInput) {
    StayListDownload(input: $input) {
      data {
        url
      }
      code
      message
    }
  }
`;

export const QUERY_INSURANCE_INFO = gql`
  query InsurancePolicySummary($input: InsurancePolicySummaryInput) {
    InsurancePolicySummary(input: $input) {
      message
      code
      data {
        provider_name
        provider_short_description
        provider_logo_url
        reference_id
        coverage_start_timestamp
        coverage_end_timestamp
        hotel_id
        room_type_id
        policy_number
        qty
        premium
        guest_price
        total_premium
        total_guest_price
        urls {
          tnc_url
          benefit_url
          claim_url
        }
      }
    }
  }
`;

export const QUERY_LIST_COUNTRY = gql`
  query ListCountry {
    ListCountry {
      data {
        id
        country_name
        alpha_2_code
        alpha_3_code
        numeric_code
        dial_code
        flag_url
        status
      }
      message
      code
    }
  }
`;

export const QUERY_LIST_CITY = gql`
  query ListCity($input: ListCityInput) {
    ListCity(input: $input) {
      data {
        id
        province_id
        name
      }
      message
      code
    }
  }
`;

export const QUERY_DOOR_PANTOO_STATUS = gql`
  query DoorPantooStatus($input: DoorPantooStatusInput) {
    DoorPantooStatus(input: $input){
      message
      code
      data {
        con
        cs
        ct
        et
        lt
        pi
        ri
        rn
        st
        ty
      }
    }
  }
`;

export const QUERY_EXTRABED_STATUS = gql`
  query RoomExtrabedStatus($input: RoomExtrabedStatusInput!) {
    RoomExtrabedStatus(input: $input) {
      data {
        enable_bed_lock
      }
      message
      error
    }
  }
`

export const QUERY_STAY_EXTRABED = gql`
  query StayForExtrabedOrder($input: StayForExtrabedOrderInput) {
    StayForExtrabedOrder(input: $input) {
      data {
        id
        hotel_id
        hotel_room_id
        check_in_date
        check_out_date
        duration
        remaining_duration
        extrabed_sku
        hotel_name
        room_name
        bpad_device_list {
          ae_resource_id
          ae_resource_name
          position
          enable_bed_lock     
        }
        webhook_url
      }
      message
      code
    }
  }
`